import UtilityBar from '@ux/utility-bar';
import SalesFooter from '@ux/sales-footer';
import AbstractHeader, { LazyBrowserDeprecationBanner } from '@ux/abstract-header';
import { events } from '@ux/header-util';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBrowser, useBreakpoints } from '@ux/component-utilities';
import { URL } from '@ux/util';
import memoizeOne from 'memoize-one';
import { IntlProvider, FormattedMessage  } from '@godaddy/react-mintl';

/**
 * @typedef {object} Item
 * @prop {string|React.ReactElement} [title] The label of the item (may also be specified as caption)
 * @prop {string} [caption] The label of the item (may also be specified as title)
 * @prop {string} [href] The URL to link the item to
 * @prop {React.MouseEventHandler} [onClick] A click event handler to run when the item is clicked
 * @prop {string} [className] A CSS class name to apply to the item
 * @prop {string} [target] The HTML target value to apply to the item
 * @prop {string} [id] A unique identifier for the item
 * @prop {string} [eid] The analytics EID to apply to the item
 * @prop {string} [icon] An icon name to use alongside the item label
 * @prop {boolean} [active] Whether the item is currently active
 * @prop {object} [dataAttrs] A set of data-* attributes to apply to the item
 * @prop {boolean} [mobileOnly] Whether the item should only show in a mobile view (and thus excluded from this nav)
 * @prop {Item[]} [children] An array of children Items
 */

/**
 * Render and expose the Header as a functional Component.
 *
 * @param {Object} props Properties
 * @returns {ReactElement} Sales Header.
 * @api public
 */
class Header extends AbstractHeader {
  constructor(props) {
    super(...arguments);

    const { components = {} } = props;
    this.state = {
      ...this.state,
      unauthenticated: this.isUnauthenticated(components.utilityBar),
      backLink: props.backLink || null,
      CartComponent: props.CartComponent || null,
      cartUrl: props.urls.checkout.href || null
    };

    this.updateCartComponent = this.updateCartComponent.bind(this);
    this.updateCartUrl = this.updateCartUrl.bind(this);
    this.getCustomerState = this.getCustomerState.bind(this);
    this.updateBackLink = this.updateBackLink.bind(this);
    this.updateCart =  this.updateCart.bind(this);
  }

  /**
   * Helper to update back link.
   *
   * @param {Object} data Updated back link object.
   * @param {Function} done Continuation to call when complete.
   * @api public
   */
  updateBackLink(data, done = () => {}) {
    this.setState(
      {
        backLink: data
      },
      done
    );
  }

  /**
   * Helper to update cart component.
   *
   * @param {React.ReactElement} component Updated React component.
   * @param {Function} done Continuation to call when complete.
   * @api public
   */
  updateCartComponent(component, done = () => {}) {
    this.setState(
      {
        CartComponent: component
      },
      done
    );
  }

  /**
   * Helper to update cart url.
   *
   * @param {React.ReactElement} url Updated cart url.
   * @param {Function} done Continuation to call when complete.
   * @api public
   */
  updateCartUrl(url, done = () => {}) {
    this.setState(
      {
        cartUrl: url
      },
      done
    );
  }

  /**
   * Helper to update cart count.
   *
   * @param {Number} items Updated cart count.
   * @param {Function} done Continuation to call when complete.
   * @api public
   */
  updateCart(items, done = () => {}) {
    this.setState(
      {
        items
      },
      done
    );
  }

  render() {
    return (
      <HeaderComponentWrapper
        { ...this.props }
        { ...this.state }
        getCustomerState={ this.getCustomerState }
        customer={ this.customer }
      />
    );
  }
}

Header.propTypes = {
  navigation: PropTypes.array.isRequired,
  currentPage: PropTypes.string,
  preset: PropTypes.string,
  supportContacts: PropTypes.object,
  ProductNav: PropTypes.elementType,
  traffic: PropTypes.string,
  split: PropTypes.string,
  showSubCategory: PropTypes.bool,
  showSubNav: PropTypes.bool,
  subNavIndices: PropTypes.object,
  market: PropTypes.string.isRequired,
  privateLabelId: PropTypes.number.isRequired,
  renderBanner: PropTypes.bool
};

class HeaderComponent extends Component {
  constructor() {
    super(...arguments);

    this.getCartProps = this.getCartProps.bind(this);
    this.allNavItems = this.props.navigation.concat(this.props.discoverNav);
    this.memoizedGetCurrentHref = memoizeOne(
      (currentHrefFromProps, isBrowser, documentHref) =>
        HeaderComponent._getCurrentHrefInternal(
          currentHrefFromProps,
          isBrowser,
          documentHref
        )
    );
    this.determineHref = this.determineHref.bind(this);
  }

  /**
   * Updates navigation without using state.
   */
  updateNavigation() {
    // because window.location might change
    this.forceUpdate();
  }

  getCartProps(customerState) {
    const { props } = this;
    const items = customerState.items;
    let prefix = 'uxp.hyd.sales_header.';
    let cartEid = 'cart_empty.link.click';
    if (props.seechange) {
      prefix = 'uxp.hyd.sales_header_seechange.';
    }
    if (items > 0) {
      cartEid = 'cart_full.link.click';
    }
    cartEid = prefix + cartEid;

    return {
      items,
      market: 'en-US',
      messages: props.messages,
      checkout: props.cartUrl,
      eid: cartEid,
      className: 'cart-link',
      showEmpty: true,
      textOnly: false
    };
  }

  /**
   * Compares two URLs, ignoring any trailing slashes.
   *
   * @param {any} url1 URL to check.
   * @param {any} url2 URL to compare.
   * @param {Boolean} [ignoreQueryStrings=false] Whether to ignore query strings.
   * @returns {Boolean} Whether URLs match
   */
  urlMatches(url1, url2, ignoreQueryStrings = false) {
    if (typeof url1 !== 'string' || typeof url2 !== 'string') {
      return false;
    }

    if (ignoreQueryStrings) {
      url1 = new URL(url1, true).set('query', {}).href;
      url2 = new URL(url2, true).set('query', {}).href;
    }

    return url1.replace(/\/$/, '') === url2.replace(/\/$/, '');
  }

  /**
   * Get the current page href for comparison of active item
   * @returns {String} current href
   * @private
   */
  getCurrentHref() {
    return this.memoizedGetCurrentHref(
      this.props.currentHref,
      isBrowser(this.props.breakpoint),
      this.getDocumentLocation()
    );
  }

  /**
   * Gets document location.
   *
   * @returns {String} current location or null if run on the server side
   * @private
   */
  getDocumentLocation() {
    if (typeof window !== 'undefined') {
      return document && document.location && document.location.href;
    }
    return null;
  }

  /**
   * Get the current page href for comparison of active item
   * @param  {Boolean} currentHrefFromProps href from props
   * @param  {Boolean} isBrowser if isBrowser
   * @param  {String}  documentHref from document.location.href
   * @returns {String} current href
   * @private
   */
  static _getCurrentHrefInternal(
    currentHrefFromProps,
    isBrowser,
    documentHref
  ) {
    //
    // Logic originally from
    // https://github.secureserver.net/UX/product-nav/blob/119ebd4ffc55e41a4bfebe1c973f55de0c3e35bc/lib/product-nav.js#L37
    //
    let currentHref = currentHrefFromProps;

    if (!currentHref && isBrowser) {
      currentHref = documentHref;
    }

    if (currentHref) {
      const currentUrl = new URL(currentHref, true);
      const qsCurrentHref = currentUrl.query.currentPage;
      if (qsCurrentHref) {
        currentHref = qsCurrentHref;
      } else {
        // Remove query string for comparisons
        currentUrl.set('query', {});
        currentHref = currentUrl.href;
      }
    }

    return currentHref;
  }

  /**
   * Determines href string to set on a tag.
   *
   * @param {String} [id] String sent in by user.
   * @returns {String} Href to set on a tag or null if run on the server side
   */
  determineHref(id) {
    if (id) {
      // If they provided an id, just let pass that string as href.
      return `#${id}`;
    }

    // fallback logic
    if (typeof window === 'undefined') {
      // Make sure we're okay for server rendering
      return;

    }
    const focusOn = ['#main-content', '#main', '#root'].find(sel => document.querySelector(sel));
    // If we found something better than our default use it, otherwise let the link handle it
    if (focusOn) {
      return focusOn;
    }

    return '#uxContent';
  }

  /**
   * Pulls items for header-nav from allNavItems (discovernav + productnav)
   * @param  {Number} options.subcategoryIndex
   * index for the active subcategory from sitecore data
   * @param  {Number} options.categoryIndex
   * index for the active category from sitecore data
   * @param {string} [currentHref] The current page URL.
   * @returns {(undefined|Object[])} Array of objects to use for header-nav
   */
  getHeaderNavItems({ categoryIndex, subcategoryIndex }, currentHref) {
    if (categoryIndex === false || categoryIndex >= this.allNavItems.length) {
      return;
    }
    const { showSubCategory } = this.props;
    const items = this.allNavItems[categoryIndex].children;

    if (
      subcategoryIndex ||
      (subcategoryIndex === 0 && subcategoryIndex < items.length)
    ) {
      items[subcategoryIndex].active = true;
      if (isBrowser(this.props.breakpoint) && items[subcategoryIndex].children) {
        items[subcategoryIndex].children.some(child => {
          if (child.href && this.urlMatches(currentHref, child.href, true)) {
            child.active = true;
            return true;
          }
        });
      }
    }

    if (subcategoryIndex === false || !showSubCategory) {
      return items;
    }

    return items[subcategoryIndex].children;
  }

  render() {
    const props = this.props;
    const {
      components,
      helpcenterGuides,
      utilityBar,
      ProductNav,
      messages,
      skipToMainContentLink
    } = props;

    const ubComponents = components && components.utilityBar;
    const customerState = props.getCustomerState();
    const helpLink = helpcenterGuides
      ? 'Sales:HelpByGoDaddyGuides'
      : 'Shared:Help:HeaderLink';
    const showAccountTrayComponent =
      !props.isReseller &&
      customerState.loggedIn;
    const skipToContentTitle = skipToMainContentLink && skipToMainContentLink.caption ? skipToMainContentLink.caption :
      <FormattedMessage id='Shared:Common:SkipToMainContent' />;
    const skipToContentSelector = this.determineHref(skipToMainContentLink && skipToMainContentLink.id);

    return (
      // eslint-disable-next-line no-return-assign
      <React.Fragment>
        <IntlProvider locale={ props.market } messages={ messages }>
          <div ref={ r => (this.headerElement = r) } role='banner'>
            <a href={ skipToContentSelector } className='skip-to-main-content' >
              { skipToContentTitle }
            </a>
            <LazyBrowserDeprecationBanner
              supportMatrix={ props.supportMatrix }
              whitelistedUserAgents={ props.whitelistedUserAgents }
              disableDeprecationBanner={ props.disableDeprecationBanner }
              blacklistedBrowsers={ props.blacklistedBrowsers }
              urls={ props.urls } />
            <UtilityBar
              { ...props }
              { ...utilityBar }
              { ...customerState }
              cart={ this.getCartProps(customerState) }
              fullHelpLinkId={ helpLink }
              components={ ubComponents }
              showAccountTrayComponent={ showAccountTrayComponent }
            />
            { ProductNav && (
              <ProductNav
                { ...props }
                components={ components && components.productNav }
              />
            ) }
          </div>
          <FormattedMessage id='Shared:Common:MainContentStarts'>
            {text => (
              <span aria-label={ text } id='uxContent' role='navigation'></span>
            )}
          </FormattedMessage>
        </IntlProvider>
      </React.Fragment>
    );
  }
}

HeaderComponent.propTypes = {
  eidPrefix: PropTypes.string,
  urlArgs: PropTypes.object,
  components: PropTypes.object,
  renderBanner: PropTypes.bool,
  isReseller: PropTypes.bool,
  getCustomerState: PropTypes.func,
  messages: PropTypes.object,
  market: PropTypes.string,
  ProductNav: PropTypes.elementType,
  utilityBar: PropTypes.object,
  helpcenterGuides: PropTypes.bool,
  showSubCategory: PropTypes.bool,
  currentHref: PropTypes.string,
  urls: PropTypes.object,
  seechange: PropTypes.bool,
  discoverNav: PropTypes.array,
  navigation: PropTypes.array,
  supportMatrix: PropTypes.object.isRequired,
  whitelistedUserAgents: PropTypes.array,
  disableDeprecationBanner: PropTypes.bool,
  cartUrl: PropTypes.string,
  blacklistedBrowsers: PropTypes.arrayOf(
    PropTypes.shape({
      browser: PropTypes.string,
      version: PropTypes.number
    })
  ),
  skipToMainContentLink: PropTypes.shape({
    id: PropTypes.string,
    caption: PropTypes.string
  }),
  breakpoint: PropTypes.string.isRequired
};

function HeaderComponentWrapper(props) {
  const { breakpoint } = useBreakpoints('tablet', 'desktop');
  return <HeaderComponent breakpoint={ breakpoint } { ...props }/>;
}

class Footer extends Component {
  componentDidMount() {
    events.emit('mount', 'footer', this);
    events.emit('mount:footer', this);
  }

  render() {
    const { ResellerSalesFooter, ...props } = this.props;

    return ResellerSalesFooter ? (
      <ResellerSalesFooter { ...props } />
    ) : (
      <SalesFooter { ...props } seechange={ true } />
    );
  }
}

Footer.propTypes = {
  ResellerSalesFooter: PropTypes.elementType
};

export { Header, HeaderComponent, Footer };
